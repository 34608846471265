<template>
<div class='linksManager'>
	<Scroll>
		<div v-if="items.length" class='content'>
			<div class='totals'>
				<b>Total: {{items.length}}</b> &nbsp;&mdash; Users: {{items.filter(i=>i.linkToObjType==="User").length}} &mdash; Teams: {{items.filter(i=>i.linkToObjType==="Team").length}} &mdash; Organizations: {{items.filter(i=>i.linkToObjType==="Organization").length}}
			</div>
			<TableColumns v-if='items.length'
				:columns='[{
					label: "From",
					flex: 1
				},{
					label: "Type",
					flex: .75
				},{
					label: "Folder",
					flex: .75
				},{
					label: "To",
					flex: 1
				}]'
			/>
			<div class='items'>
				<div v-for='(item, indx) in items' :key='indx' class='link'>
					<div class='from'>
						<b>{{item.linkFromObjType}}:</b> {{item.linkFromObjName}}
					</div>
					<div class='type'>
						<template v-if='item.folderType==="exerciseFolder"'>Exercises</template>
						<template v-else-if='item.folderType==="setFolder"'>Templates</template>
						<template v-else-if='item.folderType==="educationFolder"'>Education</template>
						<template v-else-if='item.folderType==="outcomeFolder"'>Outcomes</template>
					</div>
					<div class='path'>
						{{item.path}}
					</div>
					<div class='to'>
						<b>{{item.linkToObjType}}:</b> {{item.linkToObjName}}						
					</div>
				</div>
			</div>
		</div>
	</Scroll>
	<transition name='fade'><Loading v-show='working' /></transition>
</div>
</template>

<script>
import { linksApi } from '@/services/api/modules/manager/links'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'
import TableColumns from '@/components/common/TableColumns'

export default {
	name: 'Links',
	components: { Scroll, Loading, TableColumns },
	data() { return {
		working: false,	
		items: [],
	}},
	methods: {
		async fetch() {
			this.working=true
			const [err, result] = await linksApi.touch('fetch')
			this.items = result.items
			this.working=false
		}
	},
	async mounted() {
		await this.fetch()
	}
}
</script>

<style lang='scss'>

.linksManager {
	@include absTopLeft;
	@include fill;
	background: $color-neutral-panel;
	.content { padding: $size-gutter * 2; }
	.totals {
		height: $size-control-height;
		padding: $size-gutter * 2;
		font-size: $size-font-standard;
		display: flex;
		align-items: center;
		b { color: $color-primary-accent; }
	}
	.tableColumns { position: sticky; top: -1px; }
	.items {
		border-bottom: 1px solid $color-neutral-shadow;
		.link {
		display: flex;
			> div { 
				padding: $size-gutter * 2;
				border-left: 1px solid $color-neutral-shadow;
				&:last-child { border-right: 1px solid $color-neutral-shadow; }
				&.from { flex: 1; }
				&.type { flex: .75; }
				&.path { flex: .75; }
				&.to { flex: 1; }
				b { color: $color-primary-accent; }
			}
			background: #fff;
			height: $size-control-height;
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
		}	
	}

}
</style>