var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linksManager"},[_c('Scroll',[(_vm.items.length)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"totals"},[_c('b',[_vm._v("Total: "+_vm._s(_vm.items.length))]),_vm._v("  — Users: "+_vm._s(_vm.items.filter(i=>i.linkToObjType==="User").length)+" — Teams: "+_vm._s(_vm.items.filter(i=>i.linkToObjType==="Team").length)+" — Organizations: "+_vm._s(_vm.items.filter(i=>i.linkToObjType==="Organization").length)+" ")]),(_vm.items.length)?_c('TableColumns',{attrs:{"columns":[{
					label: "From",
					flex: 1
				},{
					label: "Type",
					flex: .75
				},{
					label: "Folder",
					flex: .75
				},{
					label: "To",
					flex: 1
				}]}}):_vm._e(),_c('div',{staticClass:"items"},_vm._l((_vm.items),function(item,indx){return _c('div',{key:indx,staticClass:"link"},[_c('div',{staticClass:"from"},[_c('b',[_vm._v(_vm._s(item.linkFromObjType)+":")]),_vm._v(" "+_vm._s(item.linkFromObjName)+" ")]),_c('div',{staticClass:"type"},[(item.folderType==="exerciseFolder")?[_vm._v("Exercises")]:(item.folderType==="setFolder")?[_vm._v("Templates")]:(item.folderType==="educationFolder")?[_vm._v("Education")]:(item.folderType==="outcomeFolder")?[_vm._v("Outcomes")]:_vm._e()],2),_c('div',{staticClass:"path"},[_vm._v(" "+_vm._s(item.path)+" ")]),_c('div',{staticClass:"to"},[_c('b',[_vm._v(_vm._s(item.linkToObjType)+":")]),_vm._v(" "+_vm._s(item.linkToObjName)+" ")])])}),0)],1):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.working),expression:"working"}]})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }