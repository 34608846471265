import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/'
	},
	post: {},
	put: {},
	delete: {}
}

export const linksApi = buildApi('links', routes, '/manager/links')

